import UrlCover from '../assets/bg/cover.jpg';
import UrlGallery from '../assets/bg/gallery.jpg';
import UrlBride from '../assets/bg/bride.jpg';
import UrlGroom from '../assets/bg/groom.jpg';
import UrlBatik from '../assets/pattern/batik.png';
import UrlBatikLandscape from '../assets/pattern/batik-l.png';
import UrlCastel from '../assets/icons/castel.png';
import urlFlowerLeft from '../assets/icons/flower-l.png';
import urlFlowerRight from '../assets/icons/flower-r.png';
import UrlFrame from '../assets/border/frame.png';
import UrlWeddingLogo from '../assets/icons/cover-logo.png';
import UrlCountdown from '../assets/bg/countdown.jpg';
import UrlFrameCountdown from '../assets/border/countdown.png';
import UrlDesktopView from '../assets/bg/desktop-view.jpg';

export const BACKGROUND = {
  cover: UrlCover,
  batik: UrlBatik,
  batikLandscape: UrlBatikLandscape,
  countdown: UrlCountdown,
  desktop: UrlDesktopView,
  loveStory: '',
};

// couple image
export const IMG_BRIDE = {
  boy: UrlGroom,
  girl: UrlBride,
};

export const IMG_LOGO_WEDDING = UrlWeddingLogo;
export const IMG_COVER_GALLERY = UrlGallery;
export const IMG_CASTEL = UrlCastel;
export const IMG_FRAME = { default: UrlFrame, countdown: UrlFrameCountdown };
export const IMG_FLOWER = { left: urlFlowerLeft, right: urlFlowerRight };

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
